import { React } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
//import { AiFillGithub } from "react-icons/ai";
//import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
    return(
        <Container fluid className="home-about-section" id="about">
            <Container>
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>
                            VOU ME APRESENTAR
                        </h1>
                        <p className="home-about-body">
                            Sou apaixonado por ciência e tecnologia. Adoro explorar
                            novos horizontes, aprendendo continuamente para alcançar
                            o melhor em tudo o que faço, seja no meu trabalho ou nas
                            minhas contribuições para o mundo.
                            <br />
                            <br />
                            Tenho grande interesse em áreas como 
                            <i>
                                <b className="purple">
                                    {" "}Ciência de Dados e Inteligência Artificial.
                                </b>
                            </i>
                            <br />
                            <br />
                            Estou sempre em busca de desenvolver soluções inovadoras que
                            façam a diferença, aplicando meus conhecimentos para criar impacto
                            positivo e transformar desafios em oportunidades.
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img
                                src={myImg}
                                className="img-fluid"
                                alt="avatar"
                            />
                        </Tilt>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Home2;
