import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Particle from "../Particle";
// import triumfImg from "../../Assets/triumf.webp"
// import piccoloImg from "../../Assets/piccolo.webp"
// import clancysImg from "../../Assets/clancys.webp"
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";
import WorkCards from "./WorkCards";
import Resume from "./Resume";

// import loadable from '@loadable/component'
// const React = loadable(() => import("react"));
// const { Col, Container, Row } = loadable(() => import("react-bootstrap"));
// const Techstack = loadable(() => import("./Techstack"));
// const Toolstack = loadable(() => import("./Toolstack"));
// const WorkCards = loadable(() => import("./WorkCards"));
// const Resume = loadable(() => import("./Resume"));
// const Particle = loadable(() => import("../Particle"));
// const triumfImg = loadable(() => import("../../Assets/triumf.webp"));
// const piccoloImg = loadable(() => import("../../Assets/piccolo.webp"));
// const clancysImg = loadable(() => import("../../Assets/clancys.webp"));

function Work() {
  return (
    <Container fluid id="work" className="work-section">
      <Particle />
      <Container id="workexperience">
        <h1 className="project-heading">
          Experiência <strong className="purple">de Trabalho </strong>
        </h1>
        <br></br>
        <Container>
          <Row>

            <Col md={4}>
              <WorkCards
                title="PET (Programa de Educação Tutorial)"
                job="Pesquisador"
                description=
                {"Realizações de atividades de pesquisa e extensão multidisciplinar."}
                link="https://www.pet.ifc-camboriu.edu.br/"
              />
            </Col>

            <Col md={4}>
              <WorkCards
                title="Fundação ASTEF"
                job="Analista de TI"
                description=
                {"Realizações de análises de riscos, como base na mineração dos dados e desenvolvimento de um " +
                 "protótipo de SIG (Sistema de Informação Gerencial) para prestações de contas e automatização das " + 
                 "análises de riscos dos projetos administrados pela fundação."}
                link="https://fastef.ufc.br/"
              />
            </Col>

            <Col md={4}>
              <WorkCards
                title="Dell Lead"
                job="Desenvolvedor Python"
                description=
                {"Envolvimento nas atividades relacionadas à implementação dos algoritmos de IA " + 
                 "(Inteligência Artificial) e regras de negócio."}
                link="https://leadfortaleza.com.br/portal"
              />
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
              <Col md={12}>
                <WorkCards
                  title="Meireles & Freitas Advogados Associados"
                  job="Desenvolvedor de Software"
                  description=
                  {""}
                  link="https://www.meirelesefreitas.adv.br"
                />
              </Col>
          </Row>
        </Container>
        <br></br>

        <Resume />

        <h1 className="project-heading">
          Skills
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Ferramentas</strong> que eu uso
        </h1>
        <Toolstack />

      </Container>
    </Container>
  );
}

export default Work;
